export function scrollToItem(
  item?: Element,
  behavior: ScrollBehavior = 'smooth',
  inline: ScrollLogicalPosition = 'end',
  block: ScrollLogicalPosition = 'nearest'
) {
  if (item) {
    window &&
      window.requestAnimationFrame(() =>
        item.scrollIntoView({
          block,
          //behavior, hide because there is a problem with Edge/Chrome
          inline,
        })
      );
  }
}
