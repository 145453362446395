import React, { FC, ReactElement } from "react";
import throttle from 'lodash/throttle';
import './ItemScrollView.css';
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./Arrows";

interface OwnProps {
    data: any;
    version: string;
}

const onWheel = throttle((api: any, ev: any) => {
  const isThouchpad = Math.abs(ev.persist()) !== 0 || Math.abs(ev.deltaY) < 15

  if (isThouchpad) {
    ev.persist()
    return false
  }

  if (ev.deltaY < 0) {
    api.scrollNext()
  } else if (ev.deltaY > 0) {
    api.scrollPrev()
  }
}, 250)

const ItemScrollView: FC<OwnProps> = (props: OwnProps): ReactElement => {
  const [position, setPosition] = React.useState(0);

  const onInit = React.useCallback(({ scrollContainer }) => {
    scrollContainer.current.scrollLeft = position;
  }, [position]);

  const savePosition = React.useCallback(throttle(({
    scrollContainer
  }: any) => {
    setPosition(scrollContainer.current.scrollLeft)
  }, 500), []);

  if(props.version !== 'mobile'){
      return (
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onInit={onInit}
            onScroll={savePosition}
            onWheel={onWheel}
          >
            {props.data}
          </ScrollMenu>
      );
  } else {
    return (
      <ScrollMenu
        onInit={onInit}
        onScroll={savePosition}
        onWheel={onWheel}
      >
        {props.data}
      </ScrollMenu>
     );
  }
}

export default ItemScrollView;
