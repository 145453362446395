import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { ImageHandler } from '../../logic/ImageHandler';

function Arrow({
  children,
  disabled,
  onClick,
  image
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
  image: string;
}) {
  const imageHandler = ImageHandler.getInstance();
  return (
    <img alt="img" src={imageHandler.getObjectStorageAppImage(image)} onClick={onClick} style={{ width:'64px' , height:'64px', margin:'auto' }}/>
  );
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()} image="arrowLeft.png">
    </Arrow>
  );
}

export function RightArrow() {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators
  } = React.useContext(VisibilityContext);

  // console.log({ isLastItemVisible });
  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()} image="arrowRight.png">
    </Arrow>
  );
}
